import React from 'react'
import { images } from 'config/images'

export const IOSAppDevSerProps = {
  ppcbannerProps: {
    heading: (
      <>
        <span className="highlight-head">iOS app development</span> to give you
        a competitive edge
      </>
    ),
    para: (
      <>
        Let’s create innovative iOS apps that{' '}
        <span className="color-through">leave remarkable footprints</span> on
        customers’ mind. We build award-winning apps for iPhone, iPad, Apple
        Watch, and Apple TV
      </>
    ),
    formCTA: 'Get a Free Quote',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        We build <span className="highlight-head">best-in-class </span>
        mobile experiences for iOS
      </>
    ),
    buildAppPara: (
      <>
        We have a team of{' '}
        <span className="color-through">full stack iOS developers</span> with
        rich industry experience and knowledge of the latest iOS 12 and
        standards of Swift, XCode, and Cocoa.
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    videoNote: 'Play video to see how we work',
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogosrc: images.FujiFilmGray,
        clientLogosrcg: images.fujiFilmLogo,
        clientLogoAlt: 'Fuji Film',
      },
      {
        clientLogosrc: images.renesaslogoGray,
        clientLogosrcg: images.renesasLogo,
        clientLogoAlt: 'Renesas',
      },
      {
        clientLogosrc: images.Hyundai_Glovis_logoGray,
        clientLogosrcg: images.hyundaiGlovisLogo,
        clientLogoAlt: 'Hyundai Glovis',
      },
      {
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogosrc: images.mitsubishilogoGray,
        clientLogosrcg: images.mitsubishiLogo,
        clientLogoAlt: 'Mitsubishi Electric',
      },
      {
        clientLogosrc: images.TrygGray,
        clientLogosrcg: images.trygCLogo,
        clientLogoAlt: 'Tryg',
      },
      {
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogosrcg: images.fihCLogo,
        clientLogosrc: images.FIHGray,
        clientLogoAlt: 'Fih',
      },
      {
        clientLogosrc: images.HiltonGray,
        clientLogosrcg: images.hiltonLogo,
        clientLogoAlt: 'Hilton',
      },
      {
        clientLogosrc: images.kirloskarlogoGray,
        clientLogosrcg: images.kirloskarBrothersLogo,
        clientLogoAlt: 'kirloskar Brothers',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  ppcServiceProps: {
    heading: (
      <>
        How do we create
        <br />
        <span className="highlight-head">Unique iOS</span> Apps company
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'Designed for Target Users',
        serviceParagraph: (
          <>
            Our UI/UX designers <strong>use grids and minimalism</strong> with
            creativity to carve applications best for your end user’s
            experience.
          </>
        ),
        serviceIcon: images.customSoftwareIcon,
        serviceAlt: 'Automation',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: 'Scalability & Performance',
        serviceParagraph: (
          <>
            We build iOS apps by using <strong>MVVM+C</strong>, caching, and
            multi-threading to optimize performance and improve battery
            consumption.
          </>
        ),
        serviceIcon: images.softwareTesting2,
        serviceAlt: 'Mobile',
        serviceClass: 'pale',
      },
      {
        id: '3',
        serviceHeading: 'Test Driven Development',
        serviceParagraph: (
          <>
            Our team of developers use <strong>TDD and Agile</strong>{' '}
            methodologies and work closely with the clients to come up with
            effective, intuitive and engaging apps.
          </>
        ),
        serviceIcon: images.enterprise,
        serviceAlt: 'Manual',
        serviceClass: 'pale-grey',
      },
      {
        id: '4',
        serviceHeading: 'iOS App Testing',
        serviceParagraph: (
          <>
            We don’t just test functionality or match developed features with
            your requirements, <strong>we test to ensure</strong> that the final
            product is bug-free.
          </>
        ),
        serviceIcon: images.webAppDevIcon,
        serviceAlt: 'Software',
        serviceClass: 'ice-blue',
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        <span className="highlight-head">Benefits</span> of working with <br />
        Simform
      </>
    ),
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Strategy and consulting to set the right goals',
        workingBenefitsContent: (
          <>
            We co-create apps by understanding your business needs first. We
            identify processes that can be streamlined and build a{' '}
            <strong>tech delivery roadmap</strong> to align with your goals.
          </>
        ),
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Product development approach to mobile apps',
        workingBenefitsContent: (
          <>
            Our team takes your ideas and turns them into apps with high ROI and
            retention rates. Our <strong>UX driven approach</strong> with user
            research, usability testing, and design thinking ensure an engaging
            app.
          </>
        ),
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Incorporate Security Best Practices',
        workingBenefitsContent: (
          <>
            Mobile app <strong>testing best practice</strong> reduces the
            security risks, tests potential vulnerabilities and incorporates
            data encryption. This ensures that an application, APIs and its data
            is secure from potential attacks.
          </>
        ),
      },
    ],
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        Case studies on how{' '}
        <span className="highlight-head">iOS applications</span> helped these
        companies
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Building EV charging platform to revolutionize the way we power',
        caseStudiesParaGraph:
          '…clean and quiet on-site power to substitute or supplement the use of diesel generators for a variety of remote power needs…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle: 'Helping luxury shoe brand go digital',
        caseStudiesParaGraph:
          "…take customized orders by applying various combinations of fabrics, motifs, and monograms based on the customer's preferences…",
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/stubbs-woottons/',
      },
      {
        caseStudiesTitle: 'Helping retailers modernize shopping experience',
        caseStudiesParaGraph:
          '…solving the multi-billion dollar problem of faster checkouttimes, customer loyalty programs, and deeper analytics...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        caseStudiesTitle: 'Shortcut to get featured on Redbull website',
        caseStudiesParaGraph:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },

  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink: 'https://www.simform.com/mvc-mvp-mvvm-ios-app-development/',
        featurecardTitle:
          'Ending the debate: MVC vs MVP vs MVVM for iOS application development',
      },
      {
        blogLink: 'https://www.simform.com/mobile-app-performance/',
        featurecardTitle: 'How to Improve your Mobile App’s Performance?',
      },
      {
        blogLink:
          'https://www.simform.com/mobile-application-security-data-vulnerabilities/',
        featurecardTitle:
          'Mobile Application Security: Checklist for Data Security and Vulnerabilities',
      },
    ],
  },
  ppcGetTouchProps: {
    formHeading: 'Get in Touch',
    personImg: images.contactKellyPowell,
    personImg2x: images.contactKellyPowell2x,
    personName: 'Kelly Powell',
    personDesignation:
      'Helping businesses to accelerate growth by creating modern applications.',
    personEmail: 'kelly@simform.com',
    buttonName: 'Call us now',
    buttonURL: '/contact/',
  },
}
