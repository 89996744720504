import React from 'react'
import Layout from 'components/Layout/Layout'
import styled from 'styled-components'
import PPCBanner from 'components/PPCBanner/PPCBanner'
import PPCService from 'components/PPCService/PPCService'
import ProcessFlow from 'components/ProcessFlow/ProcessFlow'
import CaseStudySlider from 'components/CaseStudySlider/CaseStudySlider'
import FeatureResources from 'components/FeatureResources/FeatureResources'
import PPCGetInTouch from 'components/PPCGetInTouch/PPCGetInTouch'
import { IOSAppDevSerProps } from 'components/Props/lp/ios-app-development-services'
import { lg, sm, md } from 'config/variables'
import BuildApplication from 'components/BuildApplication/BuildApplication'
import { graphql } from 'gatsby'
import SEO from 'components/Seo'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'

export const IOSAppDevSerPage = styled.div`
  .ppcbanner {
    .mobile-btn-row {
      ${md(`
        display: block;
      `)}
    }
  }
  .benefit-work-sec {
    padding: 108px 0 55px;
    ${lg(`
      padding: 70px 0 50px;
    `)}
    ${sm(`
      padding: 50px 0 0px;
    `)}
  }
  .feature-resource-section {
    margin-bottom: 100px;
    background: transparent;
    ${lg(`
      margin-bottom: 50px;
    `)}
    ${md(`
      margin-bottom: 20px;
    `)} 
    .feature-listing {
      padding-bottom: 50px;
    }
    .contact-info-card {
      display: none;
    }
  }
`

const IOSAppDevSer = props => {
  const { location } = props
  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })

  let processFlowItem = [
    props.data.process1.childImageSharp.fluid,
    props.data.process2.childImageSharp.fluid,
    props.data.process3.childImageSharp.fluid,
  ]
  let posterImage = [props.data.posterThumbImage.childImageSharp.fluid]
  return (
    <Layout location={location}>
      <SEO
        title="iOS App Development Services"
        keywords={[`simform`]}
        meta={[
          {
            name: 'ROBOTS',
            content: 'NOINDEX, NOFOLLOW',
          },
        ]}
      />
      <IOSAppDevSerPage>
        <PPCBanner ppcbannerdata={IOSAppDevSerProps} {...props} />
        <BuildApplication
          buildApplicationData={IOSAppDevSerProps}
          posterImage={posterImage}
        />
        <div className="lazy-load-div">
          <PPCService ppcServicedata={IOSAppDevSerProps} />
        </div>
        {showContent && (
          <>
            <CaseStudySlider caseStudydata={IOSAppDevSerProps} {...props} />
            <ProcessFlow
              ProcessFlowData={IOSAppDevSerProps.workingBenefitsProps}
              items={processFlowItem}
            />
            <PPCGetInTouch ppcGetTouchData={IOSAppDevSerProps} />
            <FeatureResources
              featureresourceData={IOSAppDevSerProps}
              {...props}
            />
          </>
        )}
      </IOSAppDevSerPage>
    </Layout>
  )
}

export default IOSAppDevSer

export const query = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/mobile-app-banner@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 519) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case1: file(relativePath: { regex: "/case-onlymob@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case2: file(relativePath: { regex: "/case-safari@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case3: file(relativePath: { regex: "/case-ipad@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case4: file(relativePath: { regex: "/case-mobile@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case5: file(relativePath: { regex: "/redbull-case@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication1: file(relativePath: { regex: "/blog-app-debate@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication2: file(
      relativePath: { regex: "/blog-app-performance@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication3: file(
      relativePath: { regex: "/blog-mobileapp-security@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process1: file(relativePath: { regex: "/ill-deliveryroadmap@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 376) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process2: file(relativePath: { regex: "/ill-retentionrates@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process3: file(relativePath: { regex: "/ill-secuirityrisks@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 382) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    posterThumbImage: file(relativePath: { regex: "/poster-img.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
